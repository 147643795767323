<template>
  <div class="mx-1" v-if="canVerb(resource, 'edit')">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <!-- <div class="mx-1"> -->
          <div class="mt-2">
            <b-form @submit.prevent="">
              <b-card class="filter">
                <b-row>
                  <div class="col-md-3">
                    <base-select
                      name="anno"
                      label="Anno"
                      :options="anni"
                      v-model="form[rep].request_input.anno"
                    />
                    <!-- v-model="filter.byAttribute.anno" -->
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="anno_di_confronto"
                      label="Anno Confronto"
                      :options="anni_di_confronto"
                      v-model="form[rep].request_input.anno_di_confronto"
                    />
                    <!-- v-model="filter.byAttribute.anno_di_confronto" -->
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="solo_mese_di"
                      label="Solo mese di"
                      :options="mesi"
                      v-model="form[rep].request_input.solo_mese_di"
                      @input="onInputMeseDi"
                    />
                    <!-- v-model="filter.byAttribute.solo_mese_di" -->
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="fino_al_mese"
                      label="Fino al mese"
                      :options="mesi_fino"
                      v-model="form[rep].request_input.fino_al_mese"
                      @input="onInputMeseFino"
                    />
                    <!-- v-model="filter.byAttribute.fino_al_mese" -->
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="formato_rami_statscont"
                      label="Tipologie"
                      :options="tipologie"
                      v-model="form[rep].request_input.formato_rami_statscont"
                    />
                    <!-- v-model="filter.byAttribute.formato_rami_statscont" -->
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="formato_valori_statscont"
                      label="Valori"
                      :options="valori"
                      v-model="form[rep].request_input.formato_valori_statscont"
                    />
                    <!-- v-model="filter.byAttribute.formato_valori_statscont" -->
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="salesmen"
                      label="Produttori"
                      :options="salesmen"
                      v-model="form[rep].request_input.produttori"
                      :multiple="true"
                      :closeOnSelect="false"
                      :taggable="salesmen_taggable"
                      :defaultHtmlMultipleSelect="true"
                      @changeTaggable="changeTaggable"
                    />
                    <!-- v-model="filter.byBroker.id" -->
                    <b-button
                      :pressed.sync="myToggleSalesemen"
                      variant="light"
                      class="mb-2"
                      @click="
                        myToggleSalesemen
                          ? selectAllSalesmen()
                          : deselectAllSalesmen()
                      "
                      >{{
                        myToggleSalesemen
                          ? "Deseleziona tutti"
                          : "Seleziona tutti"
                      }}</b-button
                    >
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="cod_compagnia"
                      label="Compagnia"
                      :options="companies"
                      v-model="form[rep].request_input.compagnie"
                      :multiple="true"
                      :taggable="true"
                    />
                    <!-- v-model="filter.byInsurer.id" -->
                    <b-button
                      :pressed.sync="myToggleCompanies"
                      variant="light"
                      class="mb-2"
                      @click="
                        myToggleCompanies
                          ? selectAllCompanies()
                          : deselectAllCompanies()
                      "
                      >{{
                        myToggleCompanies
                          ? "Deseleziona tutti"
                          : "Seleziona tutti"
                      }}</b-button
                    >
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="dettagli_per_produttore"
                      label="Dettagli per Produttore"
                      :options="[
                        { value: 'Y', text: 'Si' },
                        { value: 'N', text: 'No' },
                      ]"
                      v-model="form[rep].request_input.dettagli_per_produttore"
                    />
                    <!-- v-model="filter.byAttribute.dettagli_per_produttore" -->
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="solo_riepiloghi"
                      label="Solo Riepiloghi"
                      v-model="form[rep].request_input.solo_riepiloghi"
                      :options="[
                        { value: 'Y', text: 'Si' },
                        { value: 'N', text: 'No' },
                      ]"
                    />
                    <!-- v-model="filter.byAttribute.solo_riepiloghi" -->
                  </div>

                  <div class="form-group col-md-6 mt-4 align-self-end">
                    <b-button
                      type="button"
                      variant="lisaweb"
                      size="sm"
                      class="btn-fq"
                      :disabled="isLoadingPDF"
                      @click="onReport('pdf')"
                      ><b-spinner
                        small
                        type="grow"
                        v-show="isLoadingPDF"
                      ></b-spinner
                      >Genera PDF</b-button
                    >
                    <b-button
                      type="button"
                      variant="lisaweb"
                      size="sm"
                      :disabled="isLoadingCSV"
                      @click="onReport('csv')"
                      ><b-spinner
                        small
                        type="grow"
                        v-show="isLoadingCSV"
                      ></b-spinner
                      >Genera CSV</b-button
                    >
                    <b-button
                      class="btn-reset"
                      type="button"
                      variant="lisaweb"
                      size="sm"
                      @click="onClearFilter()"
                      >Reset</b-button
                    >
                  </div>
                </b-row>
              </b-card>
            </b-form>
          </div>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import FormMixin from "@/mixins/FormMixin";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
// import ExportsMixin from "@/mixins/ExportsMixin";
// import ReportsMixin from "@/mixins/ReportsMixin";
import BaseSelect from "@/components/form/BaseSelect";
import { mapGetters, mapActions } from "vuex";
import { canVerb } from "@/utils/permissions";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [FormMixin, CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      isLoading: false,
      // filter: this.initFilter(),
      companies: [],
      // resource: "book_entries",
      repository: "report",
      resource: "reports",
      reportCode: "STATSCONT",
      form: {
        report: {
          request_input: {
            anno: null,
            anno_di_confronto: null,
            solo_mese_di: null,
            fino_al_mese: "12",
            formato_rami_statscont: "0",
            formato_valori_statscont: "0",
            solo_riepiloghi: "N",
            dettagli_per_produttore: "N",
            compagnie: [],
            produttori: [],
          },
        },
      },
      myToggleSalesemen: false,
      myToggleCompanies: false,
      isLoadingCSV: false,
      isLoadingPDF: false,
      primoAnno: 1990,
      anni: [],
      anni_di_confronto: [],
      mesi: [
        { value: "1", text: "Gennaio" },
        { value: "2", text: "Febbraio" },
        { value: "3", text: "Marzo" },
        { value: "4", text: "Aprile" },
        { value: "5", text: "Maggio" },
        { value: "6", text: "Giugno" },
        { value: "7", text: "Luglio" },
        { value: "8", text: "Agosto" },
        { value: "9", text: "Settembre" },
        { value: "10", text: "Ottobre" },
        { value: "11", text: "Novembre" },
        { value: "12", text: "Dicembre" },
      ],
      mesi_fino: [
        { value: "1", text: "Gennaio" },
        { value: "2", text: "Febbraio" },
        { value: "3", text: "Marzo" },
        { value: "4", text: "Aprile" },
        { value: "5", text: "Maggio" },
        { value: "6", text: "Giugno" },
        { value: "7", text: "Luglio" },
        { value: "8", text: "Agosto" },
        { value: "9", text: "Settembre" },
        { value: "10", text: "Ottobre" },
        { value: "11", text: "Novembre" },
        { value: "12", text: "Dicembre" },
      ],
      tipologie: [
        { value: "0", text: "Globale" },
        { value: "1", text: "Tutte" },
        // { value: "2", text: "Tutte con dettaglio" },
        { value: "3", text: "AUTO" },
        { value: "4", text: "AUTO con dettaglio" },
        { value: "5", text: "RES" },
        { value: "6", text: "RES con dettaglio" },
        { value: "7", text: "REO" },
        { value: "8", text: "REO con dettaglio" },
        { value: "9", text: "VITA" },
      ],
      valori: [
        { value: "0", text: "PREMI" },
        { value: "1", text: "PROVV. AGENZIA" },
        { value: "2", text: "PROVV. PRODUTTORI" },
        { value: "3", text: "PREMI + PROVV. AGENZIA" },
        { value: "4", text: "PREMI + PROVV. PRODUTTORI" },
        { value: "5", text: "PROVV. AGENZIA + PROVV. PRODUTTORI" },
      ],
      salesmen: [],
      salesmen_taggable: true,
    };
  },
  methods: {
    canVerb,
    // initFilter() {
    //   let init = {
    //     byAttribute: {
    //       anno: null,
    //       anno_di_confronto: null,
    //       solo_mese_di: null,
    //       fino_al_mese: "11",
    //       formato_rami_statscont: "0",
    //       formato_valori_statscont: "0",
    //       solo_riepiloghi: "N",
    //       dettagli_per_produttore: "N",
    //     },
    //     byInsurer: {
    //       id: null,
    //     },
    //     byBroker: {
    //       id: null,
    //     },
    //   };
    //   return init;
    // },
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    setDefaultValues() {
      // this.$set(this.form[this.rep],"anno", null);
      this.$set(
        this.form[this.rep]["request_input"],
        "anno_di_confronto",
        null
      );
      // const current_month = moment().month();
      // this.$set(
      //   this.form[this.rep]["request_input"],
      //   "solo_mese_di",
      //   current_month.toString()
      // );
      this.$set(this.form[this.rep]["request_input"], "fino_al_mese", "12");
      this.$set(
        this.form[this.rep]["request_input"],
        "formato_rami_statscont",
        "0"
      );
      this.$set(
        this.form[this.rep]["request_input"],
        "formato_valori_statscont",
        "0"
      );
      this.$set(this.form[this.rep]["request_input"], "solo_riepiloghi", "N");
      this.$set(
        this.form[this.rep]["request_input"],
        "dettagli_per_produttore",
        "N"
      );
      this.$set(this.form[this.rep]["request_input"], "compagnie", []);
      this.$set(this.form[this.rep]["request_input"], "produttori", []);
    },
    onClearFilter() {
      this.myToggleSalesemen = false;
      this.myToggleCompanies = false;
      this.resetFilter();
    },
    selectAllSalesmen() {
      this.$set(
        this.form[this.rep]["request_input"],
        "produttori",
        this.salesmen.map((e) => e.value)
      );
    },
    deselectAllSalesmen() {
      this.$set(this.form[this.rep]["request_input"], "produttori", []);
    },
    selectAllCompanies() {
      this.$set(
        this.form[this.rep]["request_input"],
        "compagnie",
        this.companies.map((e) => e.value)
      );
    },
    deselectAllCompanies() {
      this.$set(this.form[this.rep]["request_input"], "compagnie", []);
    },
    onInputMeseFino(value) {
      if (value) {
        // this.filter.byAttribute.solo_mese_di = null;
        this.form[this.rep].request_input.solo_mese_di = null;
      }
    },
    onInputMeseDi(value) {
      if (value) {
        // this.filter.byAttribute.fino_al_mese = null;
        this.form[this.rep].request_input.fino_al_mese = null;
      }
    },
    arrayRange(start, stop, step) {
      return Array.from(
        { length: (stop - start) / step + 1 },
        (_, index) => start + index * step
      );
    },
    generateYears() {
      // array from current year down to primoAnno (eg: [2023, 2022, ..., 1990])
      let years = [];
      const current_year = moment().year();
      years = this.arrayRange(this.primoAnno, current_year, 1);

      return years
        .sort((a, b) => {
          return b - a;
        })
        .map((year) => {
          return { value: year, text: year };
        });
    },
    onReport(format) {
      /**
       * questi parametri non possono essere null 
        fino_al_mese: 0,
        formato_rami_statscont: null,
        formato_valori_statscont: null,
        solo_riepiloghi: null,
        dettagli_per_produttore: null
        fino al mese lo devi scegliere altrimenti default 12
        formato_rami_statscont lo devi selezionare altrimenti default è globale
        formato_valori_statscont lo devi selezionare altrimenti default è premi
        solo_riepiloghi lo selezioni altrimenti default è “N”
        lo stesso per dettagli per produttori se seleziona diventa “Y” altrimenti default è “N”
       */
      if (
        // !this.filter.byAttribute.fino_al_mese ||
        // !this.filter.byAttribute.formato_rami_statscont ||
        // !this.filter.byAttribute.formato_valori_statscont ||
        // !this.filter.byAttribute.solo_riepiloghi ||
        // !this.filter.byAttribute.dettagli_per_produttore
        (!this.form[this.rep].request_input.solo_mese_di &&
          !this.form[this.rep].request_input.fino_al_mese) ||
        !this.form[this.rep].request_input.formato_rami_statscont ||
        !this.form[this.rep].request_input.formato_valori_statscont ||
        !this.form[this.rep].request_input.solo_riepiloghi ||
        !this.form[this.rep].request_input.dettagli_per_produttore
      ) {
        let missing = {};
        // missing.fino_al_mese = !this.filter.byAttribute.fino_al_mese;
        missing.fino_al_mese = !this.form[this.rep].request_input.solo_mese_di;
        // missing.formato_rami_statscont =
        //   !this.filter.byAttribute.formato_rami_statscont;
        // missing.formato_valori_statscont =
        //   !this.filter.byAttribute.formato_valori_statscont;
        missing.tipologie =
          !this.form[this.rep].request_input.formato_rami_statscont;
        missing.valori =
          !this.form[this.rep].request_input.formato_valori_statscont;
        missing.solo_riepiloghi =
          !this.form[this.rep].request_input.solo_riepiloghi;
        missing.dettagli_per_produttore =
          !this.form[this.rep].request_input.dettagli_per_produttore;

        let msg = `Devi selezionare ${Object.keys(missing)
          .filter((key) => missing[key])
          .join(", ")}`;
        this.$showSnackbar({
          preset: "info",
          text: msg,
          showAction: false,
        });
        return;
      }
      // PUT /api/reports/customReports/:report {STATSCONT}
      // let reportCode = "STATSCONT";
      let report_id;
      let found = this.$store.state.auth.reports.find(
        (e) => e.code === this.reportCode
      );
      if (found) {
        report_id = found.id;
      }
      let payload = {
        format,
        // report_id: 40, // id report
        // request_input: {
        //   anno: "2023-01-01", // anno_base
        //   anno_di_confronto: "2022-01-01", // anno di confronto
        //   solo_mese_di: "2023-01-01", // solo il mese selezionato degli anni selezionati
        //   fino_al_mese: "2023-02-01", // fino al mese degli anni selezionati
        //   formato_rami_statscont: 8, // enum che avrai con la edit sulla tipologia di rami
        //   formato_valori_statscont: 5, // enum che avrai con la edit sulla tipologia di somma da visualizzare
        //   dettagli_per_produttore: "Y", // enum Y o N
        //   solo_riepiloghi: "Y", // enum Y o N
        // },
        report_id,
        request_input: this.form[this.rep].request_input,
      };
      const Repo = RepositoryFactory.get("report");
      // this.isLoading = true;
      this.setIsLoading(format, true);
      Repo.report(this.reportCode, payload)
        .then(() => {
          this.$showSnackbar({
            preset: "info",
            text: `Report Statistiche ${format.toUpperCase()} Inviato`,
          });
          // this.isLoading = false;
          this.setIsLoading(format, false);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          // this.isLoading = false;
          this.setIsLoading(format, false);
        });
    },
    setIsLoading(format, value) {
      switch (format) {
        case "csv":
          this.$set(this, "isLoadingCSV", value);
          break;
        case "pdf":
          this.$set(this, "isLoadingPDF", value);
          break;
        default:
          break;
      }
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getSalesmen: "salesmen",
    }),
  },
  mounted() {
    this.companies = this.getInsurers();
    this.salesmen = this.getSalesmen();
    this.anni = this.generateYears();
    this.anni_di_confronto = this.generateYears();
  },
  created() {
    // chiamare la edit per ricevere le options... etc...
    this.isLoading = true;
    /* */
    const path = "auth/";
    const resources = [];
    if (this.canVerb("reports", "index") && !this.hasLoadedResource("reports"))
      resources.push(
        this.$store
          .dispatch(`${path}reports`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.fetchEditForm(this.repository, this.reportCode).then(() => {
            this.setDefaultValues();
            this.isLoading = false;
          });
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.fetchEditForm(this.repository, this.reportCode).then(() => {
        this.setDefaultValues();
        this.isLoading = false;
      });
      this.isLoading = false;
    }
    /* */
  },
  components: {
    BaseIcon,
    BaseSelect,
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
